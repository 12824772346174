import { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { API_URL, getValFromLS, createMarkup } from "../../utils";

import "./styles.css";

import Hdg from "../../shared/Hdg";
import Alert from "../../shared/Alert";
import Select from "../../shared/Select";
import Input from "../../shared/Input";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import Tr from "./Tr";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronCircleRight,
	faArrowUpRightFromSquare,
} from "@fortawesome/pro-light-svg-icons";

const DashGuest = () => {
	const [details, setDetails] = useState({});
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [errors, setErrors] = useState([]);
	const [removing, setRemoving] = useState(false);
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const { str } = useParams();
	const formElement = useRef(null);
	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			if (getValFromLS("paUser", true)) {
				const user = JSON.parse(getValFromLS("paUser", true));

				const url = new URL(`${API_URL}/`);
				const params = {
					f: "paDashGuestInit",
					q: str,
					r: user.registrationId,
					x: `${Math.random()}`,
				};
				url.search = new URLSearchParams(params);

				try {
					const response = await fetch(url, {
						method: "GET",
						cache: "no-store",
					});
					const json = await response.json();
					if (json && json.resp === 1) {
						setDetails({ ...json });
						setAlert({});
					} else {
						setAlert({ type: "error", text: json.text });
					}
				} catch (error) {
					setAlert({ type: "error", text: "An error has occurred." });
				}
			} else {
				setAlert({ type: "error", text: "An error has occurred." });
			}
		};

		init();
	}, []);

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const user = JSON.parse(getValFromLS("paUser", true));

		const url = new URL(`${API_URL}/?f=paDashGuestResend`);
		const formData = new FormData(formElement.current);
		formData.append("guestId", str);
		formData.append("registrationId", user.registrationId);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setNames({});
				formElement.current.reset();
				//formElement.current.recipient.selectedIndex = 0;
				setMsg({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleRemove = async (e) => {
		e.preventDefault();
		setRemoving(true);

		const user = JSON.parse(getValFromLS("paUser", true));

		const url = new URL(`${API_URL}/?f=paDashGuestRemove`);
		let formData = new FormData();
		formData.append("guestId", str);
		formData.append("registrationId", user.registrationId);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				navigate("/dash/", {
					state: {
						data: { type: "success", text: json.text },
					},
				});
			}
		} catch (error) {}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div>
					<Hdg text="Participant Details" pa={true} />

					<p style={{ marginBottom: "5px" }}>
						Use this page to view participant information and manage invitation
						status.
					</p>

					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>Phone</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="center">{details.name}</td>
								<td className="center">
									<a href={`mailto:${details.email}`}>{details.email}</a>
								</td>
								<td className="center">{details.phone}</td>
							</tr>
						</tbody>
					</table>

					<table className="invitation-details-table">
						<thead>
							<tr>
								<th colSpan="3">Invitation Details</th>
							</tr>
							<tr>
								<td style={{ width: "15%" }}>Group</td>
								<td className="left">Participation Status</td>
								<td style={{ width: "15%" }}>Remove</td>
							</tr>
						</thead>

						<tbody>
							<Tr
								data={{
									...details.invite,
									guestId: details.guestId,
								}}
								remove={handleRemove}
								removing={removing}
							/>
						</tbody>
					</table>

					{details.invite.status === 2 && (
						<div className="resend-invitation-container">
							<form
								method="post"
								action="/"
								onSubmit={handleSubmit}
								ref={formElement}
								className="no-top-border"
							>
								<h3 style={{ marginTop: "0" }}>Resend Invitation</h3>

								<div className="two">
									<div>
										<Select
											name="recipient"
											label="Select Recipient"
											req={true}
											value={""}
											options={[
												{ value: "", name: "--" },
												{
													value: `${details.email}`,
													name: `Original Email (${details.email})`,
												},
												{ value: "other", name: "Alternate Email" },
											]}
											update={handleUpdate}
											errors={errors}
										/>
									</div>

									{names.recipient === "other" ? (
										<Input
											type="text"
											label="Other Email"
											req={true}
											name="email"
											value={""}
											update={handleUpdate}
											errors={errors}
											autocomplete="off"
										/>
									) : (
										<div className="empty" />
									)}
								</div>

								<div className="msg-submit">
									{msg.type && <Msg data={msg} />}

									<div
										className={
											msg.type === "working" ? "hidden" : "submit-container"
										}
									>
										<Submit
											name="Resend Invitation"
											icon={faChevronCircleRight}
										/>
									</div>
								</div>
							</form>
						</div>
					)}

					<table style={{ marginTop: "25px" }}>
						<thead>
							<tr>
								<th colSpan="2" className="hdg">
									Participant Information
								</th>
							</tr>
						</thead>
						{details.values[0].value ||
						details.values[1].value ||
						details.values[2].value ||
						details.values[3].value ||
						details.values[4].value ||
						details.values[4].value ? (
							<tbody>
								{details.values.map((item, index) => {
									return (
										<tr key={index}>
											<th className="right top" style={{ width: "30%" }}>
												{item.key}
											</th>
											<td
												className="left"
												dangerouslySetInnerHTML={createMarkup(item.value)}
												style={{ lineHeight: "19px", paddingBottom: "12.5px" }}
											/>
										</tr>
									);
								})}

								{details.manage && (
									<tr>
										<td colSpan="2">
											<Link to="edit">
												Update participant information{" "}
												<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
											</Link>
										</td>
									</tr>
								)}
							</tbody>
						) : (
							<tbody>
								<tr>
									<td
										className="center"
										colSpan="2"
										style={{ paddingTop: "15px", paddingBottom: "15px" }}
									>
										<span className="working">
											-- Details not yet submitted --
										</span>
									</td>
								</tr>
								{details.manage && (
									<tr>
										<td colSpan="2">
											<Link to="edit">
												Update participant information{" "}
												<FontAwesomeIcon
													icon={faArrowUpRightFromSquare}
													size="md"
												/>
											</Link>
										</td>
									</tr>
								)}
							</tbody>
						)}
					</table>
				</div>
			)}
		</>
	);
};

export default DashGuest;
