import { useState, useRef } from "react";
import { API_URL } from "../../utils";
import Input from "../../shared/Input";
import Textarea from "../../shared/Textarea";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";
import Alert from "../../shared/Alert";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Contact = () => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({});

	const formElement = useRef();

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=contact`);
		const formData = new FormData(formElement.current);
		//formData.append("method", names.method ? names.method : "");
		//formData.append("registrationId", user.registrationId);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({});
				setAlert({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<form
					method="post"
					action="/"
					onSubmit={handleSubmit}
					ref={formElement}
				>
					<div className="two">
						<div>
							<Input
								type="text"
								label="First Name"
								req={true}
								name="fname"
								value={names.fname}
								update={handleUpdate}
								errors={errors}
								autocomplete="given-name"
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Last Name"
								req={true}
								name="lname"
								value={names.lname}
								update={handleUpdate}
								errors={errors}
								autocomplete="family-name"
							/>
						</div>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Email Address"
								req={true}
								name="email"
								value={names.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Phone"
								req={false}
								name="phone"
								value={names.phone}
								update={handleUpdate}
								errors={errors}
								autocomplete="tel"
							/>
						</div>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="City"
								req={false}
								name="city"
								value={names.city}
								update={handleUpdate}
								errors={errors}
								autocomplete="address-level2"
							/>
						</div>
						<div>
							<Input
								type="text"
								label="State / Region"
								req={false}
								name="st"
								value={names.st}
								update={handleUpdate}
								errors={errors}
								autocomplete="address-level1"
							/>
						</div>
					</div>

					<div className="one">
						<Textarea
							label="Question / Comment"
							req={true}
							name="comments"
							value={names.comments}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<div className="msg-submit">
						{msg.type && <Msg data={msg} />}

						<div
							className={msg.type === "working" ? "hidden" : "submit-container"}
						>
							<Submit name="Submit Inquiry" icon={faChevronCircleRight} />
						</div>
					</div>

					<p>
						We will not share your email address with third-parties. For more
						information on how we use your information, see our{" "}
						<a href="/privacy-policy/">Privacy Policy</a>.
					</p>
				</form>
			)}
		</>
	);
};

export default Contact;
