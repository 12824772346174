import "./styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCircleCheck,
  faCircleX,
} from "@fortawesome/pro-light-svg-icons";

const Notice = ({ type = "notice", text }) => {
  return (
    <div className={`notice ${type}`}>
      <FontAwesomeIcon
        icon={
          type === "success"
            ? faCircleCheck
            : type === "error"
            ? faCircleX
            : faCircleExclamation
        }
        size="lg"
      />
      <span>{text}</span>
    </div>
  );
};

export default Notice;
