import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { API_URL, getValFromLS, scroll } from "../../utils";

import Hdg from "../../shared/Hdg";
import Alert from "../../shared/Alert";
import Notice from "../../shared/Notice";

import Table from "./Table";

const DashHome = () => {
	const [details, setDetails] = useState({});
	const [tables, setTables] = useState([]);
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const location = useLocation();

	useEffect(() => {
		const init = async () => {
			if (getValFromLS("user", true)) {
				const user = JSON.parse(getValFromLS("user", true));

				const url = new URL(`${API_URL}/`);
				const params = {
					f: "wldDashHomeInit",
					q: user.registrationId,
					x: `${Math.random()}`,
				};
				url.search = new URLSearchParams(params);

				try {
					const response = await fetch(url, {
						method: "GET",
						cache: "no-store",
					});
					const json = await response.json();
					if (json && json.resp === 1) {
						setDetails({
							totalSeats: json.totalSeats,
							totalTables: json.totalTables,
							filled: json.filled,
						});
						setTables(json.tables);
						setAlert({});
					} else {
						setAlert({ type: "error", text: json.text });
					}
				} catch (error) {
					setAlert({ type: "error", text: "An error has occurred." });
				}
			} else {
				setAlert({ type: "error", text: "An error has occurred." });
			}
		};
		scroll();
		init();
	}, []);

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div>
					<Hdg text="Sponsor Registration Overview" />

					<p style={{ marginBottom: "5px" }}>
						The sponsor dashboard is available for you to manage your guests’
						attendance. To begin inviting your guests, please{" "}
						<Link to="/dash/invite">click here</Link>.
					</p>

					{location.state?.data?.type && (
						<div style={{ marginBottom: "25px", width: "100%" }}>
							<Notice
								type={location.state.data.type}
								text={location.state.data.text}
							/>
						</div>
					)}

					{details.filled === 0 && (
						<div style={{ marginBottom: "25px", width: "100%" }}>
							<Notice
								type="notice"
								text="Get started by inviting a guest to attend under your sponsor registration."
							/>
						</div>
					)}

					{tables.map((table) => {
						return <Table data={table} key={table.tableNumber} />;
					})}
				</div>
			)}
		</>
	);
};

export default DashHome;
