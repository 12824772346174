import "./styles.css";

import Details from "../../pages-wld/Details";

const WLDPubOverview = ({ guest }) => {
	return (
		<div>
			<div className="overview">
				<div
					className="logo-container"
					style={{ backgroundColor: "rgba(0, 92, 185, 0.1)", padding: "0" }}
				>
					<img
						src="https://cmegrouptourchampionship.com/k-api/assets/wld-logo.png?1200"
						alt="WLD Logo"
						style={{ width: "75%" }}
					/>
				</div>

				<div className="details-container">
					<Details />
				</div>
			</div>

			<div className="help">
				Questions? Please contact {guest.registration.name} with{" "}
				{guest.registration.company} at{" "}
				<a href={`mailto:${guest.registration.email}`}>
					{guest.registration.email}
				</a>
				.
			</div>
		</div>
	);
};

export default WLDPubOverview;
