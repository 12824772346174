import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = ({ name, icon, click }) => {
  return (
    <div className="button">
      <button type="button" onClick={click}>
        <span>
          <span>{name}</span>
          {icon && <FontAwesomeIcon icon={icon} />}
        </span>
      </button>
    </div>
  );
};

export default Button;
