import "./styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/pro-light-svg-icons";

const Radios = ({ label, name, data, checked, click, labelBold = true }) => {
  return (
    <>
      <p style={{ fontWeight: labelBold ? "bold" : "normal" }}>{label}</p>

      {data.map((item, index) => {
        return (
          <div className="radio" key={index}>
            <button
              type="button"
              onClick={() => click(name, item.value)}
              key={index}
              disabled={item.disabled}
              style={{ color: item.disabled ? "#ccc" : "inherit" }}
            >
              <div>
                <FontAwesomeIcon
                  icon={
                    item.disabled
                      ? faCircleXmark
                      : item.value === checked
                      ? faCircleCheck
                      : faCircle
                  }
                  size="lg"
                  style={{ fontSize: "1rem" }}
                />
                <span>{item.name}</span>
              </div>
            </button>
          </div>
        );
      })}
    </>
  );
};

export default Radios;
