import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { scroll, getValFromLS } from "../../utils";

import Hdg from "../../shared/Hdg";
import Alert from "../../shared/Alert";

const PubAccepted = () => {
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const navigate = useNavigate();

	useEffect(() => {
		const init = () => {
			scroll();
			if (getValFromLS("paGuest", true)) {
				//const guest = JSON.parse(getValFromLS("paGuest", true));

				setAlert({
					type: "success",
					text: "Thank you for completing your Pro-Am registration. We look forward to seeing you on November 19th.",
				});
			} else {
				setAlert({ type: "error", text: "An error has occurred." });
			}
		};

		init();
	}, []);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			navigate("/pub");
		}, 1500);

		return () => clearTimeout(timeoutId);
	}, []);

	return (
		<div>
			<Hdg text="Invitation" dash={false} pa={true} />

			<Alert data={alert} />
		</div>
	);
};

export default PubAccepted;
