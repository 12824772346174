function WLDFooter() {
	return (
		<div className="sponsor-logos">
			{/*
			<img
				src="https://cmegrouptourchampionship.com/k-api/assets/sponsor-acumatica.png"
				alt="Acumatica"
				id="acumatica"
			/>
			<img
				src="https://cmegrouptourchampionship.com/k-api/assets/sponsor-fifththird.png"
				alt="Fifth Third"
				id="fifththird"
			/>
			<img
				src="https://cmegrouptourchampionship.com/k-api/assets/sponsor-lmcu.png"
				alt="Lake Michigan Credit Union"
				id="lmcu"
			/>
			<img
				src="https://cmegrouptourchampionship.com/k-api/assets/sponsor-principal.png"
				alt="Principal"
				id="principal"
			/>*/}
		</div>
	);
}

export default WLDFooter;
