import { useState, useEffect, useRef } from "react";

import { API_URL } from "../../utils";

import Hdg from "../../shared/Hdg";
import Radios from "../../shared/Radios";
import Input from "../../shared/Input";
import Select from "../../shared/Select";
import Checkbox from "../../shared/Checkbox";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function Invitation({ guest, details }) {
	const [names, setNames] = useState({
		response: "",
	});
	const [msg, setMsg] = useState({});
	const [errors, setErrors] = useState([]);

	const formElement = useRef();

	useEffect(() => {
		if (details.fname) {
			formElement.current.fname.value = details.fname;
			formElement.current.lname.value = details.lname;
			formElement.current.email.value = details.email;
		}
	}, [details]);

	const handleUpdate = (name, value) => {
		if (name === "same") {
			const newValue = !value;
			setNames((names) => ({ ...names, [name]: newValue }));

			formElement.current.shipAddress.value = formElement.current.address.value;
			formElement.current.shipCity.value = formElement.current.city.value;
			formElement.current.shipSt.value = formElement.current.st.value;
			formElement.current.shipZip.value = formElement.current.zip.value;
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=wldPubHomeInvitation`);
		const formData = new FormData(formElement.current);
		formData.append("response", names.response);
		formData.append("guestId", guest.guestId);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				//window.location.reload();
				setMsg({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<div>
			<Hdg text="Accept / Decline Invitation" dash={false} />

			<p>
				<strong>{guest.registration.name}</strong> has invited you to join the{" "}
				<strong>{guest.registration.company}</strong> company at 2024 CME Group
				Tour Championship Women’s Leadership Day presented by Acumatica on{" "}
				{details.eventDate}.
			</p>

			<p style={{ marginBottom: "5px" }}>
				Once you have confirmed your acceptance, you will need to proceed
				through a brief registration process and submit required details.
			</p>

			<form
				method="post"
				action="/"
				onSubmit={handleSubmit}
				ref={formElement}
				className="no-top-border"
			>
				<div className="radios-border" style={{ marginTop: "0" }}>
					<Radios
						name="response"
						label="Please select to either accept or decline invitation:"
						data={[
							{
								value: "accept",
								name: "Accept",
							},
							{
								value: "decline",
								name: "Decline",
							},
						]}
						checked={names.response}
						click={handleUpdate}
					/>
				</div>

				<div className={names.response === "accept" ? "two" : "hidden"}>
					<div>
						<Select
							name="prefix"
							label="Prefix"
							req={false}
							value={""}
							options={[
								{ value: "", name: "--" },
								{ value: "Mr.", name: "Mr." },
								{ value: "Mrs.", name: "Mrs." },
								{ value: "Ms.", name: "Ms." },
								{ value: "Dr.", name: "Dr." },
								{ value: "Other", name: "Other" },
							]}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<div className="empty"></div>
				</div>

				<div className={names.response === "accept" ? "two" : "hidden"}>
					<div>
						<Input
							type="text"
							label="First Name"
							req={true}
							name="fname"
							value={names.fname}
							update={handleUpdate}
							errors={errors}
							autocomplete="given-name"
						/>
					</div>
					<div>
						<Input
							type="text"
							label="Last Name"
							req={true}
							name="lname"
							value={names.lname}
							update={handleUpdate}
							errors={errors}
							autocomplete="family-name"
						/>
					</div>
				</div>

				<div className={names.response === "accept" ? "two" : "hidden"}>
					<div>
						<Input
							type="text"
							label="Email Address"
							req={true}
							name="email"
							value={names.email}
							update={handleUpdate}
							errors={errors}
						/>
					</div>
					<div>
						<Input
							type="text"
							label="Preferred Phone"
							req={true}
							name="phone"
							value={names.phone}
							update={handleUpdate}
							errors={errors}
							autocomplete="tel"
						/>
					</div>
				</div>

				<h3 className={names.response === "accept" ? "" : "hidden"}>
					Business Address
				</h3>

				<div className={names.response === "accept" ? "" : "hidden"}>
					<Input
						type="text"
						label="Address"
						req={false}
						name="address"
						value={names.address}
						update={handleUpdate}
						errors={errors}
						autocomplete="street-address"
					/>
				</div>

				<div className={names.response === "accept" ? "three" : "hidden"}>
					<div>
						<Input
							type="text"
							label="City"
							req={false}
							name="city"
							value={names.city}
							update={handleUpdate}
							errors={errors}
							autocomplete="address-level2"
						/>
					</div>
					<div>
						<Input
							type="text"
							label="State / Region"
							req={false}
							name="st"
							value={names.st}
							update={handleUpdate}
							errors={errors}
							autocomplete="address-level1"
						/>
					</div>
					<div>
						<Input
							type="text"
							label="Zip Code"
							req={false}
							name="zip"
							value={names.zip}
							update={handleUpdate}
							errors={errors}
							autocomplete="postal-code"
						/>
					</div>
				</div>

				<h3 className={names.response === "accept" ? "" : "hidden"}>
					Shipping Address
				</h3>

				{names.response === "accept" && !names.same && (
					<div style={{ marginTop: "7.5px" }}>
						<Checkbox
							label="Check if shipping address is the same as business"
							name="same"
							checked={names.same}
							click={handleUpdate}
						/>
					</div>
				)}

				<div className={names.response === "accept" ? "" : "hidden"}>
					<Input
						type="text"
						label="Address"
						req={false}
						name="shipAddress"
						value={names.shipAddress}
						update={handleUpdate}
						errors={errors}
						autocomplete="street-address"
					/>
				</div>

				<div className={names.response === "accept" ? "three" : "hidden"}>
					<div>
						<Input
							type="text"
							label="City"
							req={false}
							name="shipCity"
							value={names.shipCity}
							update={handleUpdate}
							errors={errors}
							autocomplete="address-level2"
						/>
					</div>
					<div>
						<Input
							type="text"
							label="State / Region"
							req={false}
							name="shipSt"
							value={names.shipSt}
							update={handleUpdate}
							errors={errors}
							autocomplete="address-level1"
						/>
					</div>
					<div>
						<Input
							type="text"
							label="Zip Code"
							req={false}
							name="shipZip"
							value={names.shipZip}
							update={handleUpdate}
							errors={errors}
							autocomplete="postal-code"
						/>
					</div>
				</div>

				{/*
          <h3 className={names.response === "accept" ? "" : "hidden"}>
            Emergency Contact Information
          </h3>

          <div className={names.response === "accept" ? "two" : "hidden"}>
            <div className="first">
              <Input
                type="text"
                label="Name"
                req={false}
                name="emergencyName"
                value={names.emergencyName}
                update={handleUpdate}
                errors={errors}
                autocomplete="off"
              />
            </div>
            <div>
              <Input
                type="text"
                label="Phone"
                req={false}
                name="emergencyPhone"
                value={names.emergencyPhone}
                update={handleUpdate}
                errors={errors}
                autocomplete="off"
              />
            </div>
          </div>

          <div className={names.response === "accept" ? "two" : "hidden"}>
            <div>
              <Input
                type="text"
                label="Relation to Participant"
                req={false}
                name="emergencyRelation"
                value={names.emergencyRelation}
                update={handleUpdate}
                errors={errors}
                autocomplete="off"
              />
            </div>

            <div className="empty" />
          </div>
        */}

				<h3 className={names.response === "accept" ? "" : "hidden"}>
					Event Questions
				</h3>

				<div className={names.response === "accept" ? "" : "hidden"}>
					<Input
						type="text"
						label="Do you have any dietary restrictions?"
						req={false}
						name="diet"
						value={names.diet}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>

				<div className="msg-submit">
					{msg.type && <Msg data={msg} />}

					<div
						className={msg.type === "working" ? "hidden" : "submit-container"}
					>
						<Submit name="Submit Invite Response" icon={faChevronCircleRight} />
					</div>
				</div>

				<p>
					We will not share your email address with third-parties. For more
					information on how we use your information, see our{" "}
					<a href="/privacy-policy/">Privacy Policy</a>.
				</p>
			</form>
		</div>
	);
}

export default Invitation;
