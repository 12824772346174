import { useState, useEffect, useRef } from "react";

import { API_URL } from "../../utils";

import Input from "../../shared/Input";
import Select from "../../shared/Select";
import Radios from "../../shared/Radios";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function GuestInfoForm({ guest, details, success }) {
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [errors, setErrors] = useState([]);

	const formElement = useRef();

	useEffect(() => {
		if (details.fname) {
			formElement.current.prefix.value = details.prefix;
			formElement.current.fname.value = details.fname;
			formElement.current.lname.value = details.lname;
			formElement.current.email.value = details.email;
			formElement.current.phone.value = details.phone;
			/* removed per Sarah
        formElement.current.address.value = details.address;
        formElement.current.city.value = details.city;
        formElement.current.st.value = details.st;
        formElement.current.zip.value = details.zip;
      */
			formElement.current.shipAddress.value = details.shipAddress;
			formElement.current.shipCity.value = details.shipCity;
			formElement.current.shipSt.value = details.shipSt;
			formElement.current.shipZip.value = details.shipZip;
			formElement.current.handicap.value = details.handicap;
			formElement.current.rental.value = details.rental;
			if (details.rental === "" || details.rental === "no") {
			} else {
				formElement.current.flex.value = details.flex;
			}
			formElement.current.shirt.value = details.shirt;
			formElement.current.outerwear.value = details.outerwear;
			formElement.current.skort.value = details.skort;
			if (details.partyGuest === "" || details.partyGuest === "no") {
			} else {
				formElement.current.partyGuestName.value = details.partyGuestName;
			}
			if (details.tickets === "" || details.tickets === "no") {
			} else {
				formElement.current.ticketsThu.value = details.ticketsThu;
				formElement.current.ticketsFri.value = details.ticketsFri;
				formElement.current.ticketsSat.value = details.ticketsSat;
				formElement.current.ticketsSun.value = details.ticketsSun;
				formElement.current.ticketsEmail.value = details.ticketsEmail;
			}
			handleUpdate("rental", details.rental);
			handleUpdate("party", details.party);
			handleUpdate("partyGuest", details.partyGuest);
			handleUpdate("tickets", details.tickets);
		}
	}, [details]);

	const handleUpdate = (name, value) => {
		if (name === "same") {
			const newValue = !value;
			setNames((names) => ({ ...names, [name]: newValue }));

			formElement.current.shipAddress.value = formElement.current.address.value;
			formElement.current.shipCity.value = formElement.current.city.value;
			formElement.current.shipSt.value = formElement.current.st.value;
			formElement.current.shipZip.value = formElement.current.zip.value;
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=paGuestInformation`);
		const formData = new FormData(formElement.current);
		formData.append("party", names.party);
		formData.append("partyGuest", names.partyGuest);
		formData.append("tickets", names.tickets);
		formData.append("guestId", guest.guestId);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({});
				success(json.text);
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<form
			method="post"
			action="/"
			onSubmit={handleSubmit}
			ref={formElement}
			style={{ paddingTop: "15px" }}
		>
			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Select
						name="prefix"
						label="Prefix"
						req={false}
						value={""}
						options={[
							{ value: "", name: "--" },
							{ value: "Mr.", name: "Mr." },
							{ value: "Mrs.", name: "Mrs." },
							{ value: "Ms.", name: "Ms." },
							{ value: "Dr.", name: "Dr." },
							{ value: "Other", name: "Other" },
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				<div className="empty"></div>
			</div>

			<div className="two">
				<div>
					<Input
						type="text"
						label="First Name"
						req={true}
						name="fname"
						value={names.fname}
						update={handleUpdate}
						errors={errors}
						autocomplete="given-name"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Last Name"
						req={true}
						name="lname"
						value={names.lname}
						update={handleUpdate}
						errors={errors}
						autocomplete="family-name"
					/>
				</div>
			</div>

			<div className="two">
				<div>
					<Input
						type="text"
						label="Email Address"
						req={true}
						name="email"
						value={names.email}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Preferred Phone"
						req={true}
						name="phone"
						value={names.phone}
						update={handleUpdate}
						errors={errors}
						autocomplete="tel"
					/>
				</div>
			</div>

			{/*<h3>Business Address</h3>

      <div>
        <Input
          type="text"
          label="Address"
          req={false}
          name="address"
          value={names.address}
          update={handleUpdate}
          errors={errors}
          autocomplete="street-address"
        />
      </div>

      <div className="three">
        <div>
          <Input
            type="text"
            label="City"
            req={false}
            name="city"
            value={names.city}
            update={handleUpdate}
            errors={errors}
            autocomplete="address-level2"
          />
        </div>
        <div>
          <Input
            type="text"
            label="State / Region"
            req={false}
            name="st"
            value={names.st}
            update={handleUpdate}
            errors={errors}
            autocomplete="address-level1"
          />
        </div>
        <div>
          <Input
            type="text"
            label="Zip Code"
            req={false}
            name="zip"
            value={names.zip}
            update={handleUpdate}
            errors={errors}
            autocomplete="postal-code"
          />
        </div>
      </div>*/}

			<h3>Shipping Address</h3>

			{/*!names.same && (
        <div style={{ marginTop: "7.5px" }}>
          <Checkbox
            label="Check if shipping address is the same as business"
            name="same"
            checked={names.same}
            click={handleUpdate}
          />
        </div>
      )*/}

			<div>
				<Input
					type="text"
					label="Address"
					req={true}
					name="shipAddress"
					value={names.shipAddress}
					update={handleUpdate}
					errors={errors}
					autocomplete="street-address"
				/>
			</div>

			<div className="three">
				<div>
					<Input
						type="text"
						label="City"
						req={true}
						name="shipCity"
						value={names.shipCity}
						update={handleUpdate}
						errors={errors}
						autocomplete="address-level2"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="State / Region"
						req={true}
						name="shipSt"
						value={names.shipSt}
						update={handleUpdate}
						errors={errors}
						autocomplete="address-level1"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Zip Code"
						req={true}
						name="shipZip"
						value={names.shipZip}
						update={handleUpdate}
						errors={errors}
						autocomplete="postal-code"
					/>
				</div>
			</div>

			<h3>Pro-Am Questions</h3>

			<div className="two">
				<div>
					<Input
						type="text"
						label="Handicap / Index"
						req={true}
						name="handicap"
						value={names.diet}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>

				<div className="empty" />
			</div>

			<div className="two">
				<div>
					<Select
						name="rental"
						label="Do you require rental clubs?"
						req={true}
						value={""}
						options={[
							{
								value: "",
								name: "--",
							},
							{
								value: "no",
								name: "No, I will bring my own clubs",
							},
							{
								value: "mens-rh",
								name: "Yes, Men’s Right Handed",
							},
							{
								value: "mens-lh",
								name: "Yes, Men’s Left Handed",
							},
							{
								value: "ladies-rh",
								name: "Yes, Ladies Right Handed",
							},
							{
								value: "ladies-lh",
								name: "Yes, Ladies Left Handed",
							},
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				{names.rental === "" || names.rental === "no" ? (
					<div className="empty"></div>
				) : (
					<div>
						<Select
							name="flex"
							label="What is your preferred shaft flex?"
							req={true}
							value={""}
							options={[
								{
									value: "",
									name: "--",
								},
								{
									value: "stiff",
									name: "Stiff",
								},
								{
									value: "regular",
									name: "Regular",
								},
								{
									value: "senior",
									name: "Senior",
								},
								{
									value: "ladies",
									name: "Ladies",
								},
							]}
							update={handleUpdate}
							errors={errors}
						/>
					</div>
				)}
			</div>

			<div className="two">
				<div>
					<Select
						name="shirt"
						label="What is your polo shirt size?"
						req={false}
						value={""}
						options={[
							{
								value: "",
								name: "--",
							},
							{
								value: "mens-sm",
								name: "Men’s Small",
							},
							{
								value: "mens-md",
								name: "Men’s Medium",
							},
							{
								value: "mens-lg",
								name: "Men’s Large",
							},
							{
								value: "mens-xl",
								name: "Men’s X-Large",
							},
							{
								value: "mens-xxl",
								name: "Men’s XX-Large",
							},
							{
								value: "ladies-sm",
								name: "Ladies Small",
							},
							{
								value: "ladies-md",
								name: "Ladies Medium",
							},
							{
								value: "ladies-lg",
								name: "Ladies Large",
							},
							{
								value: "ladies-xl",
								name: "Ladies X-Large",
							},
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				<div>
					<Select
						name="outerwear"
						label="What is your outerwear size?"
						req={false}
						value={""}
						options={[
							{
								value: "",
								name: "--",
							},
							{
								value: "mens-sm",
								name: "Men’s Small",
							},
							{
								value: "mens-md",
								name: "Men’s Medium",
							},
							{
								value: "mens-lg",
								name: "Men’s Large",
							},
							{
								value: "mens-xl",
								name: "Men’s X-Large",
							},
							{
								value: "mens-xxl",
								name: "Men’s XX-Large",
							},
							{
								value: "ladies-sm",
								name: "Ladies Small",
							},
							{
								value: "ladies-md",
								name: "Ladies Medium",
							},
							{
								value: "ladies-lg",
								name: "Ladies Large",
							},
							{
								value: "ladies-xl",
								name: "Ladies X-Large",
							},
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
			</div>

			<div className="two">
				<div>
					<Select
						name="skort"
						label="What is your skort size (if applicable)?"
						req={false}
						value={""}
						options={[
							{
								value: "",
								name: "--",
							},
							{
								value: "na",
								name: "Not Applicable",
							},
							{
								value: "ladies-sm",
								name: "Ladies Small",
							},
							{
								value: "ladies-md",
								name: "Ladies Medium",
							},
							{
								value: "ladies-lg",
								name: "Ladies Large",
							},
							{
								value: "ladies-xl",
								name: "Ladies X-Large",
							},
							{
								value: "ladies-xxl",
								name: "Ladies XX-Large",
							},
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				<div className="empty" />
			</div>

			<h3>Pairings Party</h3>

			<div style={{ marginTop: "20px" }}>
				<Radios
					name="party"
					label="Will you be attending the Monday night Pairings Party?"
					data={[
						{
							value: "yes",
							name: "Yes",
						},
						{
							value: "no",
							name: "No",
						},
					]}
					checked={names.party}
					click={handleUpdate}
					labelBold={false}
				/>
			</div>

			{names.party === "" || names.party === "no" ? (
				<div className="empty"></div>
			) : (
				<div>
					<Radios
						name="partyGuest"
						label="Will you be bringing a guest to the Monday night Pairings Party?"
						data={[
							{
								value: "yes",
								name: "Yes",
							},
							{
								value: "no",
								name: "No",
							},
						]}
						checked={names.partyGuest}
						click={handleUpdate}
						labelBold={false}
					/>
				</div>
			)}

			{names.partyGuest === "" || names.partyGuest === "no" ? (
				<div className="empty"></div>
			) : (
				<div className="two" style={{ marginTop: "5px" }}>
					<div>
						<Input
							type="text"
							label="Guest Name"
							req={true}
							name="partyGuestName"
							value={names.partyGuestName}
							update={handleUpdate}
							errors={errors}
							autocomplete="off"
						/>
					</div>

					<div className="empty" />
				</div>
			)}

			<h3>Globe Lounge Tickets</h3>

			<div style={{ marginTop: "20px" }}>
				<Radios
					name="tickets"
					label="Will you be attending CME Group Tour Championship?"
					data={[
						{
							value: "yes",
							name: "Yes",
						},
						{
							value: "no",
							name: "No",
						},
					]}
					checked={names.tickets}
					click={handleUpdate}
					labelBold={false}
				/>
			</div>

			{names.tickets === "" || names.tickets === "no" ? (
				<div className="empty"></div>
			) : (
				<div className="two">
					<div>
						<Select
							name="ticketsThu"
							label="Thursday Tickets"
							req={false}
							value={""}
							options={[
								{ value: "0", name: "--" },
								{ value: "1", name: "1" },
								{ value: "2", name: "2" },
							]}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<div>
						<Select
							name="ticketsFri"
							label="Friday Tickets"
							req={false}
							value={""}
							options={[
								{ value: "0", name: "--" },
								{ value: "1", name: "1" },
								{ value: "2", name: "2" },
							]}
							update={handleUpdate}
							errors={errors}
						/>
					</div>
				</div>
			)}

			{names.tickets === "" || names.tickets === "no" ? (
				<div className="empty"></div>
			) : (
				<div className="two">
					<div>
						<Select
							name="ticketsSat"
							label="Saturday Tickets"
							req={false}
							value={""}
							options={[
								{ value: "0", name: "--" },
								{ value: "1", name: "1" },
								{ value: "2", name: "2" },
							]}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<div>
						<Select
							name="ticketsSun"
							label="Sunday Tickets"
							req={false}
							value={""}
							options={[
								{ value: "0", name: "--" },
								{ value: "1", name: "1" },
								{ value: "2", name: "2" },
							]}
							update={handleUpdate}
							errors={errors}
						/>
					</div>
				</div>
			)}

			{names.tickets === "" || names.tickets === "no" ? (
				<div className="empty"></div>
			) : (
				<div className="two">
					<div>
						<Input
							type="text"
							label="Email Address to send Tickets to"
							req={true}
							name="ticketsEmail"
							value={names.email}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<div className="empty" />
				</div>
			)}

			<div className="msg-submit">
				{msg.type && <Msg data={msg} />}

				<div className={msg.type === "working" ? "hidden" : "submit-container"}>
					<Submit
						name="Update Participant Information"
						icon={faChevronCircleRight}
					/>
				</div>
			</div>

			<p>
				We will not share your email address with third-parties. For more
				information on how we use your information, see our{" "}
				<a href="/privacy-policy/">Privacy Policy</a>.
			</p>
		</form>
	);
}

export default GuestInfoForm;
