import { Routes, Route } from "react-router-dom";
import { getValFromLS } from "./utils";

import Home from "./pages-wld/DashHome";
import Invite from "./pages-wld/DashInvite";
import GuestEdit from "./pages-wld/DashGuestEdit";
import Guest from "./pages-wld/DashGuest";
import Statuses from "./pages-wld/DashStatuses";
import Details from "./pages-wld/DashDetails";

import NavMenu from "./shared/WLDDashNav";

function WLDDash() {
  return (
    <div className="grid">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/guest/:str/edit" element={<GuestEdit />} />
        <Route path="/guest/:str" element={<Guest />} />
        <Route path="/statuses" element={<Statuses />} />
        <Route path="/details" element={<Details />} />
      </Routes>

      <div>
        <NavMenu user={JSON.parse(getValFromLS("user", true)) || {}} />
      </div>
    </div>
  );
}

export default WLDDash;
