import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { API_URL, scroll, createMarkup, getValFromLS } from "../../utils";

import "./styles.css";

import Alert from "../../shared/Alert";
import Notice from "../../shared/Notice";
import Hdg from "../../shared/Hdg";
import Select from "../../shared/Select";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import Td from "./Td";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const DashStatuses = () => {
	const [user, setUser] = useState({});

	const [details, setDetails] = useState({
		availableSeats: [],
	});

	const [guests, setGuests] = useState([]);
	const [tables, setTables] = useState([]);
	const [tablesMax, setTablesMax] = useState([]);
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [errors, setErrors] = useState([]);
	const [notice, setNotice] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const formElement = useRef();

	useEffect(() => {
		const init = async () => {
			if (getValFromLS("user", true)) {
				const user = JSON.parse(getValFromLS("user", true));

				const url = new URL(`${API_URL}/`);
				const params = {
					f: "wldDashStatusesInit",
					q: user.registrationId,
					x: `${Math.random()}`,
				};
				url.search = new URLSearchParams(params);

				try {
					const response = await fetch(url, {
						method: "GET",
						cache: "no-store",
					});
					const json = await response.json();
					if (json && json.resp === 1) {
						setUser(user);
						setGuests(json.guests);
						setTables(json.tables);
						setTablesMax(json.tablesMax);
						setAlert({});
					} else {
						setAlert({ type: "error", text: json.text });
					}
				} catch (error) {
					setAlert({ type: "error", text: "An error has occurred." });
				}
			} else {
				setAlert({ type: "error", text: "An error has occurred." });
			}
		};

		init();
	}, [notice]);

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=wldDashStatuses`);
		const formData = new FormData(formElement.current);
		formData.append("tablesMax", JSON.stringify(tablesMax));
		formData.append("registrationId", user.registrationId);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setNotice({ type: "success", text: json.text });
				setMsg({});
			} else {
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div>
					<Hdg text="View All Guests" />

					<p style={{ marginBottom: "5px" }}>
						Please see below for all of your current guests statuses. Click a
						name to view additional details, remove from a seat or resend a
						pending invitation. Use the Table Number column to move guests to
						and from selected table assignments.
					</p>

					{notice.type && <Notice data={notice} />}

					<form
						method="post"
						action="/"
						onSubmit={handleSubmit}
						ref={formElement}
						className="no-top-border"
					>
						<table>
							<thead>
								<tr>
									<th colSpan="3" className="hdg">
										Current Guest Statuses
									</th>
								</tr>
								<tr>
									<td className="left">Guest Name</td>
									<td className="center" style={{ width: "25%" }}>
										Table Number
									</td>
									<td className="center" style={{ width: "25%" }}>
										Seat Status
									</td>
								</tr>
							</thead>
							{guests.length > 0 ? (
								<tbody>
									{guests.map((item) => {
										return (
											<tr key={item.id}>
												<td className="left">
													<span>
														<Link to={`/dash/guest/${item.guestIdEncrypted}`}>
															{item.name}
														</Link>
													</span>
												</td>
												<td className="center smaller-select">
													{item.status > 0 ? (
														<Select
															name={`guestId[${item.guestId}]`}
															label=""
															req={false}
															value={item.tableNumber}
															options={[].concat(
																tables.map((table) => {
																	return { value: table, name: table };
																})
															)}
															update={null}
															errors={errors}
														/>
													) : (
														<span>-</span>
													)}
												</td>
												<Td data={{ status: item.status, text: item.text }} />
											</tr>
										);
									})}
								</tbody>
							) : (
								<tbody>
									<tr>
										<td
											className="center"
											colSpan="3"
											style={{ paddingTop: "15px", paddingBottom: "15px" }}
										>
											<span className="working">
												-- No guests statuses found --
											</span>
										</td>
									</tr>
								</tbody>
							)}
						</table>

						<div className="msg-submit">
							{msg.type && <Msg data={msg} />}

							<div
								className={
									msg.type === "working" ? "hidden" : "submit-container"
								}
							>
								<Submit
									name="Update Table Assignments"
									icon={faChevronCircleRight}
								/>
							</div>
						</div>

						<p>
							We will not share your email address with third-parties. For more
							information on how we use your information, see our{" "}
							<a href="/privacy-policy/">Privacy Policy</a>.
						</p>
					</form>
				</div>
			)}
		</>
	);
};

export default DashStatuses;
