import { useState, useRef } from "react";
import { API_URL } from "../../utils";

import "./styles.css";

import Hdg from "../../shared/Hdg";
import Radios from "../../shared/Radios";
import Input from "../../shared/Input";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Signin = () => {
	const [names, setNames] = useState({
		access: "",
	});
	const [msg, setMsg] = useState({});
	const [errors, setErrors] = useState([]);

	const formElement = useRef();

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=wldSignin`);
		const formData = new FormData(formElement.current);
		formData.append("access", names.access ? names.access : "");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				formElement.current.reset();
				setNames({
					access: "",
				});
				setMsg({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<div className="signin">
			<Hdg text="Sign In" dash={false} />

			<p>
				Use the form below to email yourself your guest access link or to access
				the client services admin area for authorized users.
			</p>

			<form
				method="post"
				action="/"
				onSubmit={handleSubmit}
				ref={formElement}
				className="no-top-border"
			>
				<div className="radios-border" style={{ marginTop: "0" }}>
					<Radios
						name="access"
						label="Please select:"
						data={[
							{
								value: "guest",
								name: "I am a guest, I need to retrieve my invitation or registration link",
							},
							{
								value: "user",
								name: "I am a sponsor, I need to retrieve my dashboard access link",
							},
							{
								value: "admin",
								name: "I am an authorized client services admin",
							},
						]}
						checked={names.access}
						click={handleUpdate}
					/>
				</div>

				<div className="two">
					<div>
						<Input
							type="text"
							label="Email Address"
							req={true}
							name="email"
							value={""}
							update={handleUpdate}
							errors={errors}
						/>
					</div>
					<div className="empty" />
				</div>

				<div className="msg-submit">
					{msg.type && <Msg data={msg} />}

					<div
						className={msg.type === "working" ? "hidden" : "submit-container"}
					>
						<Submit name="Submit Sign In" icon={faChevronCircleRight} />
					</div>
				</div>

				<p>
					We will not share your email address with third-parties. For more
					information on how we use your information, see our{" "}
					<a href="/privacy-policy/">Privacy Policy</a>.
				</p>
			</form>
		</div>
	);
};

export default Signin;
