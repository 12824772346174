import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages-wld/Home";
import Signin from "./pages-wld/Signin";
import Exports from "./pages-wld/Exports";
import User from "./pages-wld/User";
import Guest from "./pages-wld/Guest";

import WLDDash from "./WLDDash";
import WLDPub from "./WLDPub";

import WLDFooter from "./WLDFooter";

const App = () => {
	return (
		<Router basename="/womens-leadership-day">
			<Routes>
				<Route path="/signin" element={<Signin />} />
				<Route path="/exports/:str" element={<Exports />} />

				<Route path="/user/:str" element={<User />} />
				<Route path="/guest/:str" element={<Guest />} />

				<Route path="/dash/*" element={<WLDDash />} />
				<Route path="/pub/*" element={<WLDPub />} />

				<Route path="/" element={<Home />} />
			</Routes>
			<WLDFooter />
		</Router>
	);
};

export default App;
