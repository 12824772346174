import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLocationDot,
	faCalendar,
	faSquareInfo,
	faCircleParking,
	faUser,
} from "@fortawesome/pro-light-svg-icons";

function Details() {
	return (
		<>
			<h3>
				<FontAwesomeIcon icon={faLocationDot} /> <span>Location</span>
			</h3>

			<ul>
				<li>
					The Ritz-Carlton Golf Resort, Naples
					<ul>
						<li>
							<a
								href="https://goo.gl/maps/kvnYrRDP2vxRHFfA8"
								target="_blank"
								rel="noreferrer"
							>
								2600 Tiburon Drive, Naples, FL 34109
							</a>
						</li>
						<li>Tiburon 4 Ballroom </li>
						{/*<li>(239) 593-2000</li>
            <li>
              <a
                href="https://www.ritzcarlton.com/en/hotels/naples/naples-golf"
                target="_blank"
                rel="noreferrer"
              >
                ritzcarlton.com
              </a>
            </li>*/}
					</ul>
				</li>
			</ul>

			<h3>
				<FontAwesomeIcon icon={faCalendar} /> <span>Schedule of Events</span>
			</h3>

			<ul>
				<li>
					Friday, November 22nd
					<ul>
						<li>11:00 AM - Check-In</li>
						<li>11:45 AM - Event Welcome</li>
						<li>12:00 PM - Panel Discussion</li>
						<li>12:45 PM - Luncheon</li>
						<li>1:30 PM - Keynote Speaker</li>
						<li>
							2:30 PM - Happy Hour presented by Casa Malka in 18th Green Globe
							Lounge
						</li>
					</ul>
				</li>
			</ul>

			<h3>
				<FontAwesomeIcon icon={faCircleParking} />{" "}
				<span>Parking Information</span>
			</h3>

			<p>
				All attendees receive complimentary valet parking at The Ritz-Carlton
				Golf Resort, Naples
			</p>

			{/*<h3>
				<FontAwesomeIcon icon={faSquareInfo} /> <span>Event Information</span>
			</h3>

			<p>
				Women’s Leadership Day Presented by Acumatica offers the attendees the
				opportunity to experience the LPGA season-ending tour championship
				firsthand and hear from our amazing speakers about Owning Your Voice in
				every aspect of life.
			</p>*/}

			<h3>
				<FontAwesomeIcon icon={faUser} /> <span>Speakers</span>
			</h3>

			{/*<p>Final list of speakers will be announced soon.</p>*/}

			<ul>
				<li>
					Moderator
					<ul>
						<li>Chantel McCabe, Golf Personality</li>
					</ul>
				</li>
				<li>
					Panel
					<ul>
						<li>Emma Crepeau, Chief Growth Officer, Rhone</li>
						<li>Mel Reid, LPGA Player</li>
						<li>LouLou Gonzalez, Comedian & Golf Personality</li>
					</ul>
				</li>

				<li>
					Keynote Speaker
					<ul>
						<li>Nancy Harris, Board Advisor, Non-executive Director</li>
					</ul>
				</li>
			</ul>
		</>
	);
}

export default Details;
