import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { API_URL, setValToLS } from "../../utils";

import Alert from "../../shared/Alert";

const User = () => {
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const { str } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			const url = new URL(`${API_URL}/`);
			const params = { f: "paUserInit", q: str, x: `${Math.random()}` };
			url.search = new URLSearchParams(params);

			try {
				const response = await fetch(url, {
					method: "GET",
					cache: "no-store",
				});
				const json = await response.json();
				if (json && json.resp === 1) {
					const user = {
						registrationId: json.registrationId,
						company: json.company,
						fname: json.fname,
					};
					setValToLS("paUser", JSON.stringify(user), true);
					navigate("/dash/");
				} else {
					setAlert({
						type: "error",
						text: json.text,
					});
				}
			} catch (error) {
				setAlert({
					type: "error",
					text: "An error has occurred.",
				});
			}
		};

		init();
	}, []);

	return <Alert data={alert} />;
};

export default User;
