import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faHourglass,
  faTrashAlt,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";

const Tr = ({ data, remove, removing }) => {
  return (
    <tr>
      <td>{data.group ? data.group : "-"}</td>
      <td
        className={
          data.status === 1
            ? "left success"
            : data.status === 0
            ? "left error"
            : "left working"
        }
      >
        {data.status === 2 ? (
          <div className="flex-container">
            <FontAwesomeIcon icon={faHourglass} />
            <span>{data.text}</span>
          </div>
        ) : (
          <div className="flex-container">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span>{data.text}</span>
          </div>
        )}
      </td>
      <td>
        {(data.status === 0 || data.status === 1 || data.status === 2) &&
        !removing ? (
          <button
            type="button"
            title="Remove from table"
            onClick={(e) => remove(e)}
            className="naked"
          >
            <FontAwesomeIcon icon={faTrashAlt} size="lg" className="error" />
          </button>
        ) : removing ? (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            size="lg"
            className="working"
            spin
          />
        ) : null}
      </td>
    </tr>
  );
};

export default Tr;
