import { Routes, Route } from "react-router-dom";
import { getValFromLS } from "./utils";

import Home from "./pages-pa/PubHome";
import Accepted from "./pages-pa/PubAccepted";

import OverviewMenu from "./shared/PAPubOverview";

function PADash() {
  return (
    <div className="grid">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/accepted" element={<Accepted />} />
      </Routes>

      <div>
        <OverviewMenu guest={JSON.parse(getValFromLS("paGuest", true)) || {}} />
      </div>
    </div>
  );
}

export default PADash;
