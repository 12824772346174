import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faNote,
	faGolfClub,
	faSquareInfo,
	faIdCard,
	faCircleParking,
} from "@fortawesome/pro-light-svg-icons";

function Details() {
	return (
		<>
			<h3>Pro-Am Pairings Party</h3>

			<ul>
				<li>
					<div>
						<FontAwesomeIcon icon={faSquareInfo} />{" "}
						<span>Event Information</span>
					</div>

					<ul>
						<li>
							<a
								href="https://goo.gl/maps/kvnYrRDP2vxRHFfA8"
								target="_blank"
								rel="noreferrer"
							>
								The Ritz-Carlton Naples, Tiburón - Poolside
							</a>
						</li>
						<li>Monday, November 18th</li>
						<li>6:00 pm</li>
						<li>Resort casual attire</li>
						<li>
							Parking: Please valet at the The Ritz-Carlton Naples, Tiburón
						</li>
					</ul>
				</li>
			</ul>

			<ul className="line">
				<li></li>
			</ul>

			<ul>
				<li>
					<div>
						<FontAwesomeIcon icon={faNote} /> <span>Notes</span>
					</div>
					<ul>
						<li>
							You will not know your tee time until Monday evening’s pairings
							process is over, therefore please plan accordingly.
						</li>
						<li>
							Tee times will be emailed out for those who are unable to attend
							the party.
						</li>
						<li>You may bring one guest to the Pairings Party.</li>
					</ul>
				</li>
			</ul>

			<h3>Pro-Am</h3>

			<ul>
				<li>
					<div>
						<FontAwesomeIcon icon={faSquareInfo} />{" "}
						<span>Event Information</span>{" "}
					</div>
					<ul>
						<li>
							<a
								href="https://goo.gl/maps/X6RH8dPPo4ed6J1A9"
								target="_blank"
								rel="noreferrer"
							>
								Tiburón Golf Club, Naples
							</a>
						</li>
						<li>Tuesday, November 19th</li>
						<li>Tee Times: Beginning at 7:05 am off of the 1st tee</li>
						<li>Registration opens at 6:00 am (Tiburón Golf Club Valet)</li>
						<li>
							Park at Tiburón Golf Club (drop clubs at Bag Drop, then valet).
							Parking pass will be distributed digitally prior to the event.
						</li>
						<li>
							Standard golf attire is required (collared shirts, slacks or
							shorts).
						</li>
						<li>
							Breakfast and lunch will be available for all Pro-Am participants
							as well as an on-course food and beverage experience.
						</li>
					</ul>
				</li>
			</ul>

			<ul className="line">
				<li></li>
			</ul>

			<ul>
				<li>
					<div>
						<FontAwesomeIcon icon={faGolfClub} />{" "}
						<span>Pro-Am Competition</span>{" "}
					</div>
					<ul>
						<li>Format: Shamble</li>
						<li>USGA rules govern all play</li>
					</ul>
				</li>
			</ul>

			<ul className="line">
				<li></li>
			</ul>

			<ul>
				<li>
					<div>
						<FontAwesomeIcon icon={faIdCard} />{" "}
						<span>Credential Information</span>{" "}
					</div>
					<p>
						Your Pro-Am credential will be available at Pro-Am registration
						located at front entrance to Tiburón Golf Club on Tuesday morning.
					</p>
				</li>
			</ul>

			<ul className="line">
				<li></li>
			</ul>

			<ul>
				<li>
					<div>
						<FontAwesomeIcon icon={faCircleParking} />{" "}
						<span>Parking Information</span>{" "}
					</div>
					<p>
						Parking for all Pro-Am Participants in the CME Group Tour
						Championship Pro-Am will be in the Tiburón Golf Club Clubhouse
						Parking Lot. Your Tuesday Clubhouse Parking Pass will be sent via
						email as an attachment. Please have the parking pass ready to
						present to security on Tuesday morning when you arrive to the golf
						course. Please follow the signs to Bag Drop/Valet.
					</p>
				</li>
			</ul>
		</>
	);
}

export default Details;
