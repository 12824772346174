import { useState, useEffect, useRef } from "react";
import { API_URL, getValFromLS, scroll } from "../../utils";

import Hdg from "../../shared/Hdg";
import Alert from "../../shared/Alert";
import Select from "../../shared/Select";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const DashGroups = () => {
	const [participants, setParticipants] = useState([]);
	const [options, setOptions] = useState([]);
	const [msg, setMsg] = useState({});
	const [errors, setErrors] = useState([]);
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const formElement = useRef();

	useEffect(() => {
		const init = async () => {
			if (getValFromLS("paUser", true)) {
				const user = JSON.parse(getValFromLS("paUser", true));

				const url = new URL(`${API_URL}/`);
				const params = {
					f: "paDashGroupsInit",
					q: user.registrationId,
					x: `${Math.random()}`,
				};
				url.search = new URLSearchParams(params);

				try {
					const response = await fetch(url, {
						method: "GET",
						cache: "no-store",
					});
					const json = await response.json();
					if (json && json.resp === 1) {
						setParticipants([...json.participants]);
						setOptions([...json.options]);
						setAlert({});
					} else {
						setAlert({ type: "error", text: json.text });
					}
				} catch (error) {
					setAlert({ type: "error", text: "An error has occurred." });
				}
			} else {
				setAlert({ type: "error", text: "An error has occurred." });
			}
		};
		scroll();
		init();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();

		setMsg({
			type: "working",
			text: "",
		});

		const user = JSON.parse(getValFromLS("paUser", true));

		const url = new URL(`${API_URL}/?f=paDashGroups`);
		const formData = new FormData(formElement.current);
		formData.append("registrationId", user.registrationId);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div>
					<Hdg text="Manage Participant Groups" pa={true} />

					<p style={{ marginBottom: "5px" }}>
						Use this page to assign your confirmed participants to a specific
						foursome grouping.
					</p>

					<form
						method="post"
						action="/"
						onSubmit={handleSubmit}
						ref={formElement}
						style={{ borderTop: "none", paddingTop: "5px" }}
					>
						<table style={{ marginBottom: "25px" }}>
							<thead>
								<tr>
									<th colSpan="4">{`Confirmed Pro-Am Participants`}</th>
								</tr>
								<tr>
									<td className="left">Participant Name</td>
									<td style={{ width: "25%" }}>Group Number</td>
								</tr>
							</thead>

							<tbody>
								{participants.map((item) => {
									return (
										<tr key={item.guestId}>
											<td className="left">{item.name}</td>
											<td>
												<div
													style={{ paddingLeft: "25px", paddingRight: "25px" }}
												>
													<Select
														name={`guestId[${item.guestId}]`}
														label=""
														req={false}
														value={item.group}
														options={options}
														update={null}
														errors={errors}
														shorter={true}
													/>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						<div className="msg-submit">
							{msg.type && <Msg data={msg} />}

							<div
								className={
									msg.type === "working" ? "hidden" : "submit-container"
								}
							>
								<Submit
									name="Update Participant Groups"
									icon={faChevronCircleRight}
								/>
							</div>
						</div>
					</form>
				</div>
			)}
		</>
	);
};

export default DashGroups;
