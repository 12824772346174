import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, getValFromLS } from "../../utils";

import Alert from "../../shared/Alert";
import Hdg from "../../shared/Hdg";
import Radios from "../../shared/Radios";
import Input from "../../shared/Input";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const DashInvite = () => {
	const [user, setUser] = useState({});
	const [details, setDetails] = useState({});
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [errors, setErrors] = useState([]);
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const formElement = useRef();
	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			if (getValFromLS("paUser", true)) {
				const user = JSON.parse(getValFromLS("paUser", true));

				const url = new URL(`${API_URL}/`);
				const params = {
					f: "paDashInviteInit",
					q: user.registrationId,
					x: `${Math.random()}`,
				};
				url.search = new URLSearchParams(params);

				try {
					const response = await fetch(url, {
						method: "GET",
						cache: "no-store",
					});
					const json = await response.json();
					if (json && json.resp === 1) {
						setUser(user);
						setDetails({
							availableSpots: json.availableSpots,
						});
						setAlert({});
					} else {
						setAlert({ type: "error", text: json.text });
					}
				} catch (error) {
					setAlert({ type: "error", text: "An error has occurred." });
				}
			} else {
				setAlert({ type: "error", text: "An error has occurred." });
			}
		};

		init();
	}, []);

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=paDashInvite`);
		const formData = new FormData(formElement.current);
		formData.append("method", names.method ? names.method : "");
		formData.append("registrationId", user.registrationId);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				if (json.method === "myself" || json.method === "add") {
					navigate(`/dash/guest/${json.guestId}/edit`, {
						state: {
							data: { type: "success", text: json.text },
						},
					});
				} else {
					navigate("/dash/", {
						state: {
							data: { type: "success", text: json.text },
						},
					});
				}
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div>
					<Hdg text="Add / Invite Participants" pa={true} />

					<p style={{ marginBottom: "5px" }}>
						Use this form to immediately add or send invite emails for
						participants you wish to assign to your Pro-Am team.
					</p>

					<form
						method="post"
						action="/"
						onSubmit={handleSubmit}
						ref={formElement}
						className="no-top-border"
					>
						<div className="radios-border" style={{ marginTop: "0" }}>
							<Radios
								name="method"
								label="Please select:"
								data={[
									{
										value: "myself",
										name: "Assign yourself as a participant",
									},
									{
										value: "add",
										name: "Immediately add a participant to a spot",
									},
									{
										value: "email",
										name: "Send an email invite to a participant",
									},
								]}
								checked={names.method}
								click={handleUpdate}
							/>
						</div>

						<div className={names.method === "myself" ? "hidden" : "two"}>
							<div>
								<Input
									type="text"
									label="First Name"
									req={true}
									name="fname"
									value={names.fname}
									update={handleUpdate}
									errors={errors}
									autocomplete="given-name"
								/>
							</div>
							<div>
								<Input
									type="text"
									label="Last Name"
									req={true}
									name="lname"
									value={names.lname}
									update={handleUpdate}
									errors={errors}
									autocomplete="family-name"
								/>
							</div>
						</div>

						<div className={names.method === "myself" ? "hidden" : "two"}>
							<div>
								<Input
									type="text"
									label="Email Address"
									req={true}
									name="email"
									value={names.email}
									update={handleUpdate}
									errors={errors}
								/>
							</div>
							<div>
								<Input
									type="text"
									label="Confirm Email"
									req={true}
									name="confirm"
									value={names.confirm}
									update={handleUpdate}
									errors={errors}
									autocomplete="off"
								/>
							</div>
						</div>

						{/*<div style={{ marginTop: "15px" }}>
              <Radios
                name="tableNumber"
                label="Select Table:"
                req={true}
                data={[].concat(
                  details.availableSpots.map((item) => {
                    return {
                      value: item.value,
                      name: item.name,
                      disabled: item.disabled,
                    };
                  })
                )}
                checked={names.tableNumber}
                click={handleUpdate}
              />
            </div>*/}

						<div className="msg-submit">
							{msg.type && <Msg data={msg} />}

							<div
								className={
									msg.type === "working" ? "hidden" : "submit-container"
								}
							>
								<Submit
									name="Add / Invite Participant"
									icon={faChevronCircleRight}
								/>
							</div>
						</div>

						<p>
							We will not share your email address with third-parties. For more
							information on how we use your information, see our{" "}
							<a href="/privacy-policy/">Privacy Policy</a>.
						</p>
					</form>
				</div>
			)}
		</>
	);
};

export default DashInvite;
