import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faHourglass } from "@fortawesome/pro-light-svg-icons";

const Table = ({ data }) => {
  return (
    <table style={{ marginBottom: "25px" }}>
      <thead>
        <tr>
          <th colSpan="4">{`Pro-Am Participants (${data.numSpots} spots)`}</th>
        </tr>
        <tr>
          <td style={{ width: "12%" }}>Spot</td>
          <td style={{ width: "12%" }}>Group</td>
          <td className="left">Participant Name</td>
          <td style={{ width: "15%" }}>Status</td>
        </tr>
      </thead>

      <tbody>
        {data.guests.map((guest) => {
          return (
            <tr key={guest.spot}>
              <td className="center">{guest.spot}</td>
              <td className="center">{guest.group ? guest.group : "-"}</td>
              {guest.status === 10 ? (
                <td className="working">
                  <small>-- empty --</small>
                </td>
              ) : guest.status === 2 ? (
                <td className="left working">
                  Waiting on response from{" "}
                  <Link to={`/dash/guest/${guest.guestId}`} className="working">
                    {guest.name}
                  </Link>
                </td>
              ) : (
                <td className="left">
                  <Link to={`/dash/guest/${guest.guestId}`}>{guest.name}</Link>
                </td>
              )}

              <td className="center">
                {guest.status === 2 ? (
                  <FontAwesomeIcon
                    icon={faHourglass}
                    className="working"
                    size="lg"
                  />
                ) : guest.status === 1 ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="success"
                    size="lg"
                  />
                ) : (
                  ""
                )}{" "}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
