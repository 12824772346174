import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages-pa/Home";
import Signin from "./pages-pa/Signin";
import Exports from "./pages-pa/Exports";
import User from "./pages-pa/User";
import Guest from "./pages-pa/Guest";

import PADash from "./PADash";
import PAPub from "./PAPub";

const App2 = () => {
	return (
		<Router basename="/pro-am">
			<Routes>
				<Route path="/signin" element={<Signin />} />
				<Route path="/exports/:str" element={<Exports />} />

				<Route path="/user/:str" element={<User />} />
				<Route path="/guest/:str" element={<Guest />} />

				<Route path="/dash/*" element={<PADash />} />
				<Route path="/pub/*" element={<PAPub />} />

				<Route path="/" element={<Home />} />
			</Routes>
		</Router>
	);
};

export default App2;
