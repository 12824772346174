import { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { API_URL, getValFromLS } from "../../utils";

import Hdg from "../../shared/Hdg";
import Alert from "../../shared/Alert";
import Notice from "../../shared/Notice";

import GuestInfoForm from "../../shared/GuestInfoForm";

const DashGuestEdit = () => {
	const [guest, setGuest] = useState({});
	const [details, setDetails] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const { str } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			const url = new URL(`${API_URL}/`);
			const params = { f: "wldPubHomeInit", q: str, x: `${Math.random()}` };
			url.search = new URLSearchParams(params);

			try {
				const response = await fetch(url, {
					method: "GET",
					cache: "no-store",
				});
				const json = await response.json();
				if (json && json.resp === 1) {
					setGuest({ guestId: json.guestId });
					setDetails((details) => ({ ...details, ...json }));
					setAlert({});
				} else {
					setAlert({ type: "error", text: json.text });
				}
			} catch (error) {
				setAlert({ type: "error", text: "An error has occurred." });
			}
		};

		init();
	}, []);

	const handleSuccess = (text) => {
		navigate("/dash/", {
			state: {
				data: { type: "success", text },
			},
		});
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div>
					<Hdg text="Guest Information" />

					<p style={{ marginBottom: "5px" }}>
						Use this page to add or update your guest’s information.
					</p>

					{location.state?.data?.type && (
						<div style={{ marginBottom: "25px", width: "100%" }}>
							<Notice
								type={location.state.data.type}
								text={location.state.data.text}
							/>
						</div>
					)}

					<GuestInfoForm
						guest={guest}
						details={details}
						success={handleSuccess}
					/>
				</div>
			)}
		</>
	);
};

export default DashGuestEdit;
