import { NavLink, useNavigate } from "react-router-dom";
import { setValToLS } from "../../utils";

import "./styles.css";

import Button from "../Button";

import { faSignOut } from "@fortawesome/pro-light-svg-icons";

const WLDDashNav = ({ user }) => {
	const navigate = useNavigate();

	const handleSignout = () => {
		setValToLS("user", null, true);
		navigate("/alert", {
			state: {
				data: {
					type: "success",
					text: "You have successfully signed out of your dashboard.",
				},
				url: "/",
			},
		});
	};

	return (
		<div>
			<div className="nav">
				<h3>{user.company}</h3>
				<h4>{`Welcome, ${user.fname}`}</h4>

				<Button name="Sign Out" icon={faSignOut} click={handleSignout}></Button>

				<ol type="I">
					<li>
						<NavLink
							to="/dash/"
							className={({ isActive }) => (isActive ? "active" : undefined)}
						>
							Registration Overview
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/dash/invite"
							className={({ isActive }) => (isActive ? "active" : undefined)}
						>
							Add / Invite Guests
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/dash/statuses"
							className={({ isActive }) => (isActive ? "active" : undefined)}
						>
							View All Guests
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/dash/details"
							className={({ isActive }) => (isActive ? "active" : undefined)}
						>
							Event Details
						</NavLink>
					</li>
				</ol>
			</div>

			<div className="help">
				Questions? Please contact Alex Graf at (419) 309-2917 or email{" "}
				<a href="mailto:alex.graf@teamwass.com">alex.graf@teamwass.com</a>.
			</div>
		</div>
	);
};

export default WLDDashNav;
