const Hdg = ({ text, dash = true, pa = false }) => {
  return (
    <>
      <h2
        className="normal-case"
        style={{
          textAlign: "center",
          marginBottom: "5px",
          borderBottom: "1px solid var(--lightblue)",
          paddingBottom: "7.5px",
        }}
      >
        {pa ? "Pro-Am" : "Women’s Leadership Day"} {dash ? "Dashboard" : ""}
      </h2>

      <h3
        style={{
          textAlign: "center",
          marginBottom: "15px",
          paddingTop: "7.5px",
        }}
      >
        {text}
      </h3>
    </>
  );
};

export default Hdg;
