import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faHourglass,
} from "@fortawesome/pro-light-svg-icons";

const Td = ({ data }) => {
  return (
    <td
      className={
        data.status === 1
          ? "left success"
          : data.status === 0
          ? "left error"
          : data.status === 2
          ? "left working"
          : data.status === 4
          ? "left notice"
          : "left working"
      }
    >
      <div className="flex-container">
        {data.status === 1 ? (
          <FontAwesomeIcon icon={faCheckCircle} />
        ) : data.status === 0 ? (
          <FontAwesomeIcon icon={faTimesCircle} />
        ) : data.status === 2 ? (
          <FontAwesomeIcon icon={faHourglass} />
        ) : (
          "fa fa-info-circle"
        )}

        <span>{data.text}</span>
      </div>
    </td>
  );
};

export default Td;
