import "./styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";

const Checkbox = ({ label, name, checked, click }) => {
  return (
    <div className="checkbox">
      <button type="button" onClick={() => click(name, checked)}>
        <div>
          <FontAwesomeIcon
            icon={checked ? faSquareCheck : faSquare}
            size="lg"
            style={{ fontSize: "1rem" }}
          />
          <span>{label}</span>
        </div>
      </button>
    </div>
  );
};

export default Checkbox;
