import { Routes, Route } from "react-router-dom";
import { getValFromLS } from "./utils";

import Home from "./pages-pa/DashHome";
import Invite from "./pages-pa/DashInvite";
import GuestEdit from "./pages-pa/DashGuestEdit";
import Guest from "./pages-pa/DashGuest";
import Groups from "./pages-pa/DashGroups";
import Details from "./pages-pa/DashDetails";

import NavMenu from "./shared/PADashNav";

function PADash() {
  return (
    <div className="grid">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/guest/:str/edit" element={<GuestEdit />} />
        <Route path="/guest/:str" element={<Guest />} />
        <Route path="/groups" element={<Groups />} />
        <Route path="/details" element={<Details />} />
      </Routes>

      <div>
        <NavMenu user={JSON.parse(getValFromLS("paUser", true)) || {}} />
      </div>
    </div>
  );
}

export default PADash;
