import { useState, useEffect } from "react";
import { useSearchParams, Navigate } from "react-router-dom";

const Home = () => {
  const [str, setStr] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("user")) {
      setStr(`/user/${searchParams.get("user")}`);
    } else if (searchParams.get("guest")) {
      setStr(`/guest/${searchParams.get("guest")}`);
    } else if (searchParams.get("exports")) {
      setStr(`/exports/${searchParams.get("exports")}`);
    } else {
      setStr(`/signin`);
    }
  }, []);

  return <div>{str ? <Navigate to={str} /> : <span>Loading...</span>}</div>;
};

export default Home;
