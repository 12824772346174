import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL, setValToLS } from "../../utils";

import Alert from "../../shared/Alert";

const Guest = () => {
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const { str } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			const url = new URL(`${API_URL}/`);
			const params = { f: "wldGuestInit", q: str, x: `${Math.random()}` };
			url.search = new URLSearchParams(params);

			try {
				const response = await fetch(url, {
					method: "GET",
					cache: "no-store",
				});
				const json = await response.json();
				if (json && json.resp === 1) {
					const guest = {
						guestId: json.guestId,
						registration: {
							company: json.registrationCompany,
							name: json.registrationName,
							email: json.registrationEmail,
							phone: json.registrationPhone,
						},
					};
					setValToLS("guest", JSON.stringify(guest), true);
					navigate("/pub/");
				} else {
					setAlert({
						type: "error",
						text: json.text,
					});
				}
			} catch (error) {
				setAlert({
					type: "error",
					text: "An error has occurred.",
				});
			}
		};

		init();
	}, []);

	return <Alert data={alert} />;
};

export default Guest;
