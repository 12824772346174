export const API_URL = "https://cmegrouptourchampionship.com/k-api";

export function createMarkup(html) {
  return { __html: html };
}

export function scroll() {
  return window.jQuery("html, body").animate(
    {
      scrollTop: window.jQuery("#cmeforms-root").offset().top - 25,
    },
    200
  );
}

const isBrowser = () => typeof window !== "undefined";

export const getValFromLS = (key, useSessionStorage = false) => {
  if (useSessionStorage) {
    return isBrowser() && window.sessionStorage.getItem(key)
      ? window.sessionStorage.getItem(key)
      : undefined;
  } else {
    return isBrowser() && window.localStorage.getItem(key)
      ? window.localStorage.getItem(key)
      : undefined;
  }
};

export const setValToLS = (key, val, useSessionStorage = false) => {
  if (isBrowser() && val) {
    useSessionStorage
      ? window.sessionStorage.setItem(key, val)
      : window.localStorage.setItem(key, val);
  } else {
    useSessionStorage
      ? window.sessionStorage.removeItem(key)
      : window.localStorage.removeItem(key);
  }
  return true;
};
