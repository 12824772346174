import Hdg from "../../shared/Hdg";

import Details from "../Details";
import "../Details/styles.css";

const DashDetails = () => {
	return (
		<div>
			<Hdg text="Event Details" pa={true} />

			{/*<div className="overview">
        <div className="logo-container">
          <img
            src="https://853187.smushcdn.com/1925011/wp-content/uploads/2021/09/womens-leadership.png?lossy=0&strip=1&webp=1"
            alt="WLD Logo"
            style={{ width: "75%", padding: "5% 15% 3.25% 15%" }}
          />
        </div>
      </div>*/}

			<div className="pa" style={{ marginTop: "25px" }}>
				<Details />
			</div>
		</div>
	);
};

export default DashDetails;
