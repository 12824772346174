import { useState, useEffect } from "react";
import { API_URL, getValFromLS } from "../../utils";

import Hdg from "../../shared/Hdg";
import Alert from "../../shared/Alert";

import Invitation from "./Invitation";
import Registration from "./Registration";

const PubHome = () => {
	const [guest, setGuest] = useState({});
	const [details, setDetails] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	useEffect(() => {
		const init = async () => {
			if (getValFromLS("paGuest", true)) {
				const guest = JSON.parse(getValFromLS("paGuest", true));

				const url = new URL(`${API_URL}/`);
				const params = {
					f: "paPubHomeInit",
					q: guest.guestId,
					x: `${Math.random()}`,
				};
				url.search = new URLSearchParams(params);

				try {
					const response = await fetch(url, {
						method: "GET",
						cache: "no-store",
					});
					const json = await response.json();
					if (json && json.resp === 1) {
						setGuest(guest);
						setDetails((details) => ({ ...details, ...json }));
						setAlert({});
					} else {
						setAlert({ type: "error", text: json.text });
					}
				} catch (error) {
					setAlert({ type: "error", text: "An error has occurred." });
				}
			} else {
				setAlert({ type: "error", text: "An error has occurred." });
			}
		};

		init();
	}, []);

	return (
		<>
			{alert.type ? (
				<div>
					<Hdg text="Invitation" dash={false} pa={true} />
					<Alert data={alert} />
				</div>
			) : (
				<>
					{details.status === 2 ? (
						<Invitation guest={guest} details={details} />
					) : (
						<Registration guest={guest} details={details} />
					)}
				</>
			)}
		</>
	);
};

export default PubHome;
