import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { API_URL } from "../../utils";

import "./styles.css";

import Hdg from "../../shared/Hdg";
import Alert from "../../shared/Alert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-light-svg-icons";

const Exports = () => {
	const [exports, setExports] = useState([]);
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const { str } = useParams();

	useEffect(() => {
		const init = async () => {
			if (str) {
				const url = new URL(`${API_URL}/`);
				const params = { f: "paExportsInit", q: str, x: `${Math.random()}` };
				url.search = new URLSearchParams(params);

				try {
					const response = await fetch(url, {
						method: "GET",
						cache: "no-store",
					});
					const json = await response.json();
					if (json && json.resp === 1) {
						setExports([...json.exports]);
						setAlert({});
					} else {
						setAlert({ type: "error", text: json.text });
					}
				} catch (error) {
					setAlert({ type: "error", text: "An error has occurred." });
				}
			} else {
				setAlert({ type: "error", text: "An error has occurred." });
			}
		};

		init();
	}, []);

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="exports">
					<Hdg text="Admin Exports Area" dash={false} pa={true} />

					<p style={{ marginBottom: "15px" }}>
						Use the links below to download registration and guest data for the
						2024 event.
					</p>
					<table>
						<thead>
							<tr>
								<th colSpan="3">Pro-Am - Tuesday, November 19th</th>
							</tr>
							<tr>
								<td className="center" style={{ width: "25%" }}>
									Report Name
								</td>
								<td className="center" style={{ width: "15%" }}>
									Records
								</td>
								<td className="center">Download</td>
							</tr>
						</thead>
						<tbody>
							{exports.map((item) => {
								return (
									<tr key={item.id}>
										<td className="center">{item.name}</td>
										<td className="center">{item.records}</td>
										<td>
											{parseInt(item.records) > 0 ? (
												<a href={item.url} download>
													<FontAwesomeIcon icon={faDownload} size="lg" />
													<span>
														{item.url.replace(
															"https://cmegrouptourchampionship.com/k-api/exports/",
															""
														)}
													</span>
												</a>
											) : (
												<span>-</span>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};

export default Exports;
