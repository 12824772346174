import { Routes, Route } from "react-router-dom";
import { getValFromLS } from "./utils";

import Home from "./pages-wld/PubHome";

import OverviewMenu from "./shared/WLDPubOverview";

function WLDDash() {
  return (
    <div className="grid">
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>

      <div>
        <OverviewMenu guest={JSON.parse(getValFromLS("guest", true)) || {}} />
      </div>
    </div>
  );
}

export default WLDDash;
